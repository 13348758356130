import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_SPCKINSTRUMENTD_PERNDING:
      return {
        ...state,
        pending: true,
        serialNumbers: [],
      };
    case types.FETCH_SPCKINSTRUMENTD_SUCCESS:
      return {
        ...state,
        pending: false,
        serialNumbers: actions.payload.data,
      };
    case types.FETCH_SPCKINSTRUMENTD_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_INSTRUMENTD_PERNDING:
      return {
        ...state,
        pending: true,
        instruments: [],
      };
    case types.FETCH_INSTRUMENTD_SUCCESS:
      return {
        ...state,
        pending: false,
        instruments: actions.payload.data,
      };
    case types.FETCH_INSTRUMENTD_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_DEPLOYMENT_INSTRUMENTS_PENDING:
      return {
        ...state,
        pending: true,
        instrumentDeployments: [],
      };
    case types.FETCH_DEPLOYMENT_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        instrumentDeployments: actions.payload.data,
      };
    case types.FETCH_DEPLOYMENT_INSTRUMENTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_INSTRUMENT_BY_CATEGORY_PENDING:
      return {
        ...state,
        pending: true,
        instrumentsByCategory: [],
      };
    case types.FETCH_INSTRUMENT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        pending: false,
        instrumentsByCategory: actions.payload.data,
      };
    case types.FETCH_INSTRUMENT_BY_CATEGORY_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_INSTRUMENT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
        instrumentTypes: [],
      };
    case types.FETCH_INSTRUMENT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        instrumentTypes: actions.payload.data,
      };
    case types.FETCH_INSTRUMENT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.SET_INSTRUMENTD_UPDATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.SET_INSTRUMENTD_UPDATE_SUCCESS:
      //console.log("actions.payload.data", actions.payload.data);
      return {
        ...state,
        pending: false,
        instruments: state.instruments.map((item) => {
          if (
            actions.payload.data.instrumentDetail.instrumentSerialNumber ===
            item.instrumentDetail.instrumentSerialNumber
          ) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.SET_INSTRUMENTD_UPDATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.SET_INSTRUMENTD_UPDATES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.SET_INSTRUMENTD_UPDATES_SUCCESS:
      //console.log("actions.payload.data", actions.payload.data);
      return {
        ...state,
        pending: false,
        instruments: state.instruments.map((item) => {
          actions.payload.data.map((inst) => {
            if (
              inst.instrumentDetail.instrumentSerialNumber ===
              item.instrumentDetail.instrumentSerialNumber
            ) {
              item = { ...inst };
            }
            return inst;
          });
          return item;
        }),
      };
    case types.SET_INSTRUMENTD_UPDATES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.SET_INSTRUMENTD_INSERT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.SET_INSTRUMENTD_INSERT_SUCCESS:
      // //console.log(
      //   "SET_INSTRUMENTD_INSERT_SUCESS",
      //   state.unconfiguredInstruments,
      //   actions.payload.data.value
      // );
      if (state.instruments != null) {
        return {
          ...state,
          pending: false,
          instruments: [...state.instruments, actions.payload.data],
        };
      } else {
        return {
          ...state,
          pending: false,
          unconfiguredInstruments: state.unconfiguredInstruments.filter(
            (item) =>
              actions.payload.data.instrumentSerialNumber !==
              item.instrumentSerialNumber
          ),
        };
      }
    case types.SET_INSTRUMENTD_INSERT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_UNCONFIGURED_INSTRUMENTS_PENDING:
      return {
        ...state,
        pending: true,
        unconfiguredInstruments: [],
      };
    case types.FETCH_UNCONFIGURED_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        unconfiguredInstruments: actions.payload.data,
      };
    case types.FETCH_UNCONFIGURED_INSTRUMENTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return {
        ...state,
      };
  }
}
