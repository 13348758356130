import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    let index = -1;
    arr.map((item) => {
      if (value.id === item.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  switch (actions.type) {
    case types.FETCH_DEFAULT_PARAMETER_PENDING:
      return {
        ...state,
        pending: true,
        defaultParameter: [],
      };
    case types.FETCH_DEFAULT_PARAMETER_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultParameter: actions.payload,
      };
    case types.FETCH_DEFAULT_PARAMETER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_DEFAULT_PARAMETERS_PENDING:
      return {
        ...state,
        pending: true,
        defaultParameters: [],
      };
    case types.FETCH_DEFAULT_PARAMETERS_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultParameters: actions.payload,
      };
    case types.FETCH_DEFAULT_PARAMETERS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_DEFAULT_PARAMETER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_DEFAULT_PARAMETER_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultParameters: state.defaultParameters.map((item) => {
          if (actions.payload.data.value.id === item.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_DEFAULT_PARAMETER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_DEFAULT_PARAMETER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_DEFAULT_PARAMETER_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultParameters: [
          ...state.defaultParameters,
          actions.payload.data.value,
        ],
        // actions.payload,
      };
    case types.INSERT_DEFAULT_PARAMETER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_DEFAULT_PARAMETER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_DEFAULT_PARAMETER_SUCCESS:
      return {
        ...state,
        pending: false,
        defaultParameters: removeItemFromArray(
          state.defaultParameters,
          actions.payload.data.value
        ),
      };
    case types.REMOVE_DEFAULT_PARAMETER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
