import * as types from "../../constants";
import {
  deleteUserRolesService,
  updateUserRolesService,
  insertUserRolesService,
  unLinkedUserService,
  inviteUserService,
  responseInviteService,
  getInvitesService,
  deleteInviteUserService,
  proceedInviteUserService,
  reInviteUserService,
  enableUserService,
  getByEmailService,
  uploadPhotoService,
} from "../../services/userService";

export function uploadPhoto(base64) {
  return async (dispatch) => {
    dispatch({ type: types.USER_PHOTO_PENDING });
    return uploadPhotoService(base64)
      .then((response) => {
        dispatch({
          type: types.USER_PHOTO_IN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_PHOTO_IN_FAILURE, error });
        throw error;
      });
  };
}

export function getByEmail(email) {
  return async (dispatch) => {
    dispatch({ type: types.USER_BY_EMAIL_PENDING });
    return getByEmailService(email)
      .then((response) => {
        dispatch({
          type: types.USER_BY_EMAIL_IN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_BY_EMAIL_IN_FAILURE, error });
        throw error;
      });
  };
}

export function enableUser(credential) {
  return async (dispatch) => {
    dispatch({ type: types.USER_ENABLE_PENDING });
    return enableUserService(credential)
      .then((response) => {
        dispatch({ type: types.USER_ENABLE_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_ENABLE_IN_FAILURE, error });
        throw error;
      });
  };
}

export function reInviteUser(id) {
  return async (dispatch) => {
    dispatch({ type: types.USER_INVITE_PENDING });
    return reInviteUserService(id)
      .then((response) => {
        dispatch({ type: types.USER_INVITE_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_INVITE_IN_FAILURE, error });
        throw error;
      });
  };
}

export function proceedInvites(token, credential) {
  return async (dispatch) => {
    dispatch({ type: types.USER_PROCEED_INVITES_PENDING });
    return proceedInviteUserService(token, credential)
      .then((response) => {
        dispatch({
          type: types.USER_PROCEED_INVITES_IN_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_PROCEED_INVITES_IN_FAILURE, error });
        throw error;
      });
  };
}

export function deleteInvites(id) {
  return async (dispatch) => {
    dispatch({ type: types.USER_DELETE_INVITES_PENDING });
    return deleteInviteUserService(id)
      .then((response) => {
        dispatch({
          type: types.USER_DELETE_INVITES_IN_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_DELETE_INVITES_IN_FAILURE, error });
        throw error;
      });
  };
}

export function getInvites() {
  return async (dispatch) => {
    dispatch({ type: types.USER_GET_INVITES_PENDING });
    return getInvitesService()
      .then((response) => {
        dispatch({
          type: types.USER_GET_INVITES_IN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_GET_INVITES_IN_FAILURE, error });
        throw error;
      });
  };
}

export function responseInvite(credential) {
  return async (dispatch) => {
    dispatch({ type: types.USER_RESPONSE_INVITE_PENDING });
    return responseInviteService(credential)
      .then((response) => {
        dispatch({
          type: types.USER_RESPONSE_INVITE_IN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.USER_RESPONSE_INVITE_IN_FAILURE, error });
        throw error;
      });
  };
}

export function inviteUser(credential) {
  return async (dispatch) => {
    dispatch({ type: types.USER_INVITE_PENDING });
    return inviteUserService(credential)
      .then((response) => {
        dispatch({ type: types.USER_INVITE_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_INVITE_IN_FAILURE, error });
        throw error;
      });
  };
}

export function unLinkedUser(credential) {
  return async (dispatch) => {
    dispatch({ type: types.USER_UNLINKED_PENDING });
    return unLinkedUserService(credential)
      .then((response) => {
        dispatch({ type: types.USER_UNLINKED_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_UNLINKED_IN_FAILURE, error });
        throw error;
      });
  };
}

export function insertUserRoles(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.USER_ROLES_INSERT_PENDING });
    return insertUserRolesService(credentials)
      .then((response) => {
        dispatch({ type: types.USER_ROLES_INSERT_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_ROLES_INSERT_IN_FAILURE, error });
        throw error;
      });
  };
}

export function updateUserRoles(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.USER_ROLES_UPDATE_PENDING });
    return updateUserRolesService(credentials)
      .then((response) => {
        dispatch({ type: types.USER_ROLES_UPDATE_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_ROLES_UPDATE_IN_FAILURE, error });
        throw error;
      });
  };
}

export function deleteUserRoles(id) {
  return async (dispatch) => {
    dispatch({ type: types.USER_ROLES_DELETE_PENDING });
    return deleteUserRolesService(id)
      .then((response) => {
        dispatch({ type: types.USER_ROLES_DELETE_IN_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.USER_ROLES_DELETE_IN_FAILURE, error });
        throw error;
      });
  };
}
