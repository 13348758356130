import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    let index = -1;
    arr.map((item) => {
      if (value.id === item.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  switch (actions.type) {
    case types.FETCH_TASK_TYPE_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
        taskTypeTemplate: [],
      };
    case types.FETCH_TASK_TYPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTypeTemplate: actions.payload,
      };
    case types.FETCH_TASK_TYPE_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_TASK_TYPE_TEMPLATES_PENDING:
      return {
        ...state,
        pending: true,
        taskTypeTemplates: [],
      };
    case types.FETCH_TASK_TYPE_TEMPLATES_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTypeTemplates: actions.payload,
      };
    case types.FETCH_TASK_TYPE_TEMPLATES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_TASK_TYPE_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_TASK_TYPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTypeTemplates: state.taskTypeTemplates.map((item) => {
          if (actions.payload.data.id === item.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_TASK_TYPE_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_TASK_TYPE_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_TASK_TYPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTypeTemplates: [...state.taskTypeTemplates, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_TASK_TYPE_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_TASK_TYPE_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_TASK_TYPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTypeTemplates: removeItemFromArray(
          state.taskTypeTemplates,
          actions.payload.data
        ),
      };
    case types.REMOVE_TASK_TYPE_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
