import * as types from "../../constants";
//import { getInstrumentAlarmCondition } from "../actions/alarmActions";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    let index = -1;
    arr.map((item) => {
      if (value.alarm.alarmId === item.alarm.alarmId) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  switch (actions.type) {
    case types.FETCH_ALARMS_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        reports: [],
      };
    case types.FETCH_ALARMS_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        reports: actions.payload,
      };
    case types.FETCH_ALARMS_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_ALARM_PENDING:
      return {
        ...state,
        pending: true,
        alarm: [],
      };
    case types.FETCH_ALARM_SUCCESS:
      return {
        ...state,
        pending: false,
        alarm: actions.payload,
      };
    case types.FETCH_ALARM_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_ALARM_CONDITIONS_PENDING:
      return {
        ...state,
        pending: true,
        alarmConditions: [],
      };
    case types.FETCH_ALARM_CONDITIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        alarmConditions: actions.payload,
      };
    case types.FETCH_ALARM_CONDITIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_ALARMS_PENDING:
      return {
        ...state,
        pending: true,
        alarms: [],
      };
    case types.FETCH_ALARMS_SUCCESS:
      return {
        ...state,
        pending: false,
        alarms: actions.payload,
      };
    case types.FETCH_ALARMS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_ALARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_ALARM_SUCCESS:
      return {
        ...state,
        pending: false,
        alarms: state.alarms.map((item) => {
          if (actions.payload.data.value.alarm.alarmId === item.alarm.alarmId) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_ALARM_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_ALARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_ALARM_SUCCESS:
      return {
        ...state,
        pending: false,
        alarms: [...state.alarms, actions.payload.data.value],
        // actions.payload,
      };
    case types.INSERT_ALARM_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    //TODO: remove attribute
    //removeAttributeFromArray
    case types.REMOVE_ALARM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_ALARM_SUCCESS:
      return {
        ...state,
        pending: false,
        alarms: removeItemFromArray(state.alarms, actions.payload.data.value),
      };
    case types.REMOVE_ALARM_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
