import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_NOTE_PENDING:
      return {
        ...state,
        pending: true,
        note: [],
      };
    case types.FETCH_NOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        note: actions.payload.data,
      };
    case types.FETCH_NOTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_NOTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_NOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        note: [...(state.note ? state.note : []), actions.payload.data],
      };
    case types.INSERT_NOTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_NOTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        note: state.note.map((item) => {
          if (actions.payload.data.note.id === item.note.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_NOTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_NOTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_NOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        note: state.note.filter(
          (item) => actions.payload.data.note.id !== item.note.id
        ),
      };
    case types.REMOVE_NOTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
