import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import userReducer from "./userReducer";
import stateReducer from "./stateReducer";
import countryReducer from "./countryReducer";
import roleReducer from "./roleReducer";
import reportReducer from "./reportReducer";
import authReducer from "./authReducer";
import instDReducer from "./InstDReducer";
import enterpriseReducer from "./enterpriseReducer";
import siteReducer from "./siteReducer";
import locationReducer from "./locationReducer";
import instrumentMeasurementReducer from "./instrumentMeasurementReducer";
import categoryReducer from "./categoryReducer";
import eventReducer from "./eventReducer";
import typeReducer from "./typeReducer";
import classificationReducer from "./classificationReducer";
import attributeReducer from "./attributeReducer";
import alarmReducer from "./alarmReducer";
import systemAlarmReducer from "./systemAlarmReducer";
import measurementTypeReducer from "./measurementTypeReducer";
import operatorReducer from "./operatorReducer";
import defaultParameterReducer from "./defaultParameterReducer";
import alarmConditionReducer from "./alarmConditionReducer";
import instrumentCalibrationReducer from "./instrumentCalibrationReducer";
import instrumentDeploymentTypeReducer from "./instrumentDeploymentTypeReducer.js";
import instrumentDeploymentReducer from "./instrumentDeploymentReducer";
import taskTemplateReducer from "./taskTemplateReducer";
import taskTypeTemplateReducer from "./taskTypeTemplateReducer";
import checklistTaskTemplateReducer from "./checklistTaskTemplateReducer";
import checklistTemplateReducer from "./checklistTemplateReducer";
import checklistReducer from "./checklistReducer";
import toolboxTalkReducer from "./toolboxTalkReducer";
import tsiReducer from "./tsiReducer";
import noteReducer from "./noteReducer";
import groupReducer from "./groupReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  roleReducer,
  reportReducer,
  userReducer,
  stateReducer,
  countryReducer,
  instDReducer,
  enterpriseReducer,
  siteReducer,
  locationReducer,
  instrumentMeasurementReducer,
  categoryReducer,
  eventReducer,
  typeReducer,
  classificationReducer,
  attributeReducer,
  alarmReducer,
  systemAlarmReducer,
  measurementTypeReducer,
  operatorReducer,
  defaultParameterReducer,
  alarmConditionReducer,
  instrumentCalibrationReducer,
  instrumentDeploymentTypeReducer,
  instrumentDeploymentReducer,
  taskTemplateReducer,
  taskTypeTemplateReducer,
  checklistTaskTemplateReducer,
  checklistTemplateReducer,
  checklistReducer,
  toolboxTalkReducer,
  tsiReducer,
  noteReducer,
  groupReducer,
});
