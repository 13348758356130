import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.USER_PHOTO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.USER_PHOTO_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        url: actions.payload,
      };
    case types.USER_PHOTO_IN_FAILURE:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.USER_BY_EMAIL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.USER_BY_EMAIL_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.USER_BY_EMAIL_IN_FAILURE:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.USER_ENABLE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_ENABLE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_ENABLE_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_REINVITE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_REINVITE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_REINVITE_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_PROCEED_INVITES_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_PROCEED_INVITES_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_PROCEED_INVITES_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_DELETE_INVITES_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_DELETE_INVITES_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_DELETE_INVITES_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_GET_INVITES_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_GET_INVITES_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
        invites: actions.payload,
      };
    case types.USER_GET_INVITES_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_RESPONSE_INVITE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
        data: { isNewUser: false },
      };
    case types.USER_RESPONSE_INVITE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
        data: actions.payload,
      };
    case types.USER_RESPONSE_INVITE_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        data: { isNewUser: false },
        error: actions.error,
      };
    case types.USER_INVITE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_INVITE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_INVITE_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_UNLINKED_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_UNLINKED_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_UNLINKED_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.USER_ROLES_INSERT_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_ROLES_INSERT_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_ROLES_INSERT_IN_FAILURE:
      return {
        ...state,
        error: actions.error,
        pending: false,
        status: false,
      };
    case types.USER_ROLES_UPDATE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_ROLES_UPDATE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.USER_ROLES_UPDATE_IN_FAILURE:
      return {
        ...state,
        error: actions.error,
        pending: false,
        status: false,
      };
    case types.USER_ROLES_DELETE_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.USER_ROLES_DELETE_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: false,
      };
    case types.USER_ROLES_DELETE_IN_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
