// Theme

export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Hot Water Reports
export const HOT_WATER_REPORTS_PENDING = "HOT_WATER_REPORTS_PENDING";
export const HOT_WATER_REPORTS_SUCCESS = "HOT_WATER_REPORTS_SUCCESS";
export const HOT_WATER_REPORTS_ERROR = "HOT_WATER_REPORTS_ERROR";

// Hot Water Parameters
export const HOT_WATER_PARAMS_PENDING = "HOT_WATER_PARAMS_PENDING";
export const HOT_WATER_PARAMS_SUCCESS = "HOT_WATER_PARAMS_SUCCESS";
export const HOT_WATER_PARAMS_ERROR = "HOT_WATER_PARAMS_ERROR";

// Sites By Location Reports
export const SITES_BY_LOCATION_REPORTS_PENDING =
  "SITES_BY_LOCATION_REPORTS_PENDING";
export const SITES_BY_LOCATION_REPORTS_SUCCESS =
  "SITES_BY_LOCATION_REPORTS_SUCCESS";
export const SITES_BY_LOCATION_REPORTS_ERROR =
  "SITES_BY_LOCATION_REPORTS_ERROR";

// Sites By Location Parameters
export const SITES_BY_LOCATION_PARAMS_PENDING =
  "SITES_BY_LOCATION_PARAMS_PENDING";
export const SITES_BY_LOCATION_PARAMS_SUCCESS =
  "SITES_BY_LOCATION_PARAMS_SUCCESS";
export const SITES_BY_LOCATION_PARAMS_ERROR = "SITES_BY_LOCATION_PARAMS_ERROR";
// Users By Site Reports
export const USERS_BY_SITE_REPORTS_PENDING = "USERS_BY_SITE_REPORTS_PENDING";
export const USERS_BY_SITE_REPORTS_SUCCESS = "USERS_BY_SITE_REPORTS_SUCCESS";
export const USERS_BY_SITE_REPORTS_ERROR = "USERS_BY_SITE_REPORTS_ERROR";

// Users By Site Parameters
export const USERS_BY_SITE_PARAMS_PENDING = "USERS_BY_SITE_PARAMS_PENDING";
export const USERS_BY_SITE_PARAMS_SUCCESS = "USERS_BY_SITE_PARAMS_SUCCESS";
export const USERS_BY_SITE_PARAMS_ERROR = "USERS_BY_SITE_PARAMS_ERROR";

// Sites By User Reports
export const SITES_BY_USER_REPORTS_PENDING = "SITES_BY_USER_REPORTS_PENDING";
export const SITES_BY_USER_REPORTS_SUCCESS = "SITES_BY_USER_REPORTS_SUCCESS";
export const SITES_BY_USER_REPORTS_ERROR = "SITES_BY_USER_REPORTS_ERROR";

// Sites By User Parameters
export const SITES_BY_USER_PARAMS_PENDING = "SITES_BY_USER_PARAMS_PENDING";
export const SITES_BY_USER_PARAMS_SUCCESS = "SITES_BY_USER_PARAMS_SUCCESS";
export const SITES_BY_USER_PARAMS_ERROR = "SITES_BY_USER_PARAMS_ERROR";

// Temperature Summary Parameters
export const TEMPERATURE_SUMMARY_PARAMS_PENDING =
  "TEMPERATURE_SUMMARY_PARAMS_PENDING";
export const TEMPERATURE_SUMMARY_PARAMS_SUCCESS =
  "TEMPERATURE_SUMMARY_PARAMS_SUCCESS";
export const TEMPERATURE_SUMMARY_PARAMS_ERROR =
  "TEMPERATURE_SUMMARY_PARAMS_ERROR";

// Temperature Summary Reports
export const TEMPERATURE_SUMMARY_REPORTS_PENDING =
  "TEMPERATURE_SUMMARY_REPORTS_PENDING";
export const TEMPERATURE_SUMMARY_REPORTS_SUCCESS =
  "TEMPERATURE_SUMMARY_REPORTS_SUCCESS";
export const TEMPERATURE_SUMMARY_REPORTS_ERROR =
  "TEMPERATURE_SUMMARY_REPORTS_ERROR";


// Waste Summary Reports
export const WASTE_SUMMARY_REPORTS_PENDING =
  "WASTE_SUMMARY_REPORTS_PENDING";
export const WASTE_SUMMARY_REPORTS_SUCCESS =
  "WASTE_SUMMARY_REPORTS_SUCCESS";
export const WASTE_SUMMARY_REPORTS_ERROR =
  "WASTE_SUMMARY_REPORTS_ERROR";

// Spot Check Summary Reports
export const SPOTCHECK_SUMMARY_REPORTS_PENDING =
  "SPOTCHECK_SUMMARY_REPORTS_PENDING";
export const SPOTCHECK_SUMMARY_REPORTS_SUCCESS =
  "SPOTCHECK_SUMMARY_REPORTS_SUCCESS";
export const SPOTCHECK_SUMMARY_REPORTS_ERROR =
  "SPOTCHECK_SUMMARY_REPORTS_ERROR";

// States
export const COUNTRIES_SELECT_PENDING = "COUNTRIES_SELECT_PENDING";
export const COUNTRIES_SELECT_IN_SUCCESS = "COUNTRIES_SELECT_IN_SUCCESS";
export const COUNTRIES_SELECT_IN_FAILURE = "COUNTRIES_SELECT_IN_FAILURE";

// States
export const STATES_SELECT_PENDING = "STATES_SELECT_PENDING";
export const STATES_SELECT_IN_SUCCESS = "STATES_SELECT_IN_SUCCESS";
export const STATES_SELECT_IN_FAILURE = "STATES_SELECT_IN_FAILURE";

// Roles
export const ROLES_SELECT_PENDING = "ROLES_SELECT_PENDING";
export const ROLES_SELECT_IN_SUCCESS = "ROLES_SELECT_IN_SUCCESS";
export const ROLES_SELECT_IN_FAILURE = "ROLES_SELECT_IN_FAILURE";

// User
export const USER_REINVITE_PENDING = "USER_REINVITE_PENDING";
export const USER_REINVITE_IN_SUCCESS = "USER_REINVITE_IN_SUCCESS";
export const USER_REINVITE_IN_FAILURE = "USER_REINVITE_IN_FAILURE";

export const USER_PROCEED_INVITES_PENDING = "USER_PROCEED_INVITES_PENDING";
export const USER_PROCEED_INVITES_IN_SUCCESS =
  "USER_PROCEED_INVITES_IN_SUCCESS";
export const USER_PROCEED_INVITES_IN_FAILURE =
  "USER_PROCEED_INVITES_IN_FAILURE";

export const USER_DELETE_INVITES_PENDING = "USER_DELETE_INVITES_PENDING";
export const USER_DELETE_INVITES_IN_SUCCESS = "USER_DELETE_INVITES_IN_SUCCESS";
export const USER_DELETE_INVITES_IN_FAILURE = "USER_DELETE_INVITES_IN_FAILURE";

export const USER_GET_INVITES_PENDING = "USER_GET_INVITES_PENDING";
export const USER_GET_INVITES_IN_SUCCESS = "USER_GET_INVITES_IN_SUCCESS";
export const USER_GET_INVITES_IN_FAILURE = "USER_GET_INVITES_IN_FAILURE";

export const USER_RESPONSE_INVITE_PENDING = "USER_RESPONSE_INVITE_PENDING";
export const USER_RESPONSE_INVITE_IN_SUCCESS =
  "USER_RESPONSE_INVITE_IN_SUCCESS";
export const USER_RESPONSE_INVITE_IN_FAILURE =
  "USER_RESPONSE_INVITE_IN_FAILURE";

export const USER_PHOTO_PENDING = "USER_PHOTO_PENDING";
export const USER_PHOTO_IN_SUCCESS = "USER_PHOTO_IN_SUCCESS";
export const USER_PHOTO_IN_FAILURE = "USER_PHOTO_IN_FAILURE";

export const USER_BY_EMAIL_PENDING = "USER_BY_EMAIL_PENDING";
export const USER_BY_EMAIL_IN_SUCCESS = "USER_BY_EMAIL_IN_SUCCESS";
export const USER_BY_EMAIL_IN_FAILURE = "USER_BY_EMAIL_IN_FAILURE";

export const USER_ENABLE_PENDING = "USER_ENABLE_PENDING";
export const USER_ENABLE_IN_SUCCESS = "USER_ENABLE_IN_SUCCESS";
export const USER_ENABLE_IN_FAILURE = "USER_ENABLE_IN_FAILURE";

export const USER_INVITE_PENDING = "USER_INVITE_PENDING";
export const USER_INVITE_IN_SUCCESS = "USER_INVITE_IN_SUCCESS";
export const USER_INVITE_IN_FAILURE = "USER_INVITE_IN_FAILURE";

export const USER_UNLINKED_PENDING = "USER_UNLINKED_PENDING";
export const USER_UNLINKED_IN_SUCCESS = "USER_UNLINKED_IN_SUCCESS";
export const USER_UNLINKED_IN_FAILURE = "USER_UNLINKED_IN_FAILURE";

export const USER_ROLES_INSERT_PENDING = "USER_ROLES_INSERT_PENDING";
export const USER_ROLES_INSERT_IN_SUCCESS = "USER_ROLES_INSERT_IN_SUCCESS";
export const USER_ROLES_INSERT_IN_FAILURE = "USER_ROLES_INSERT_IN_FAILURE";

export const USER_ROLES_UPDATE_PENDING = "USER_ROLES_UPDATE_PENDING";
export const USER_ROLES_UPDATE_IN_SUCCESS = "USER_ROLES_UPDATE_IN_SUCCESS";
export const USER_ROLES_UPDATE_IN_FAILURE = "USER_ROLES_UPDATE_IN_FAILURE";

export const USER_ROLES_DELETE_PENDING = "USER_ROLES_DELETE_PENDING";
export const USER_ROLES_DELETE_IN_SUCCESS = "USER_ROLES_DELETE_IN_SUCCESS";
export const USER_ROLES_DELETE_IN_FAILURE = "USER_ROLES_DELETE_IN_FAILURE";

// Auth
export const AUTH_SELECT_IN_SUCCESS = "AUTH_SELECT_IN_SUCCESS";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_FB_SIGN_IN_SUCCESS = "AUTH_FB_SIGN_IN_SUCCESS";
export const AUTH_FB_SIGN_IN_FAILURE = "AUTH_FB_SIGN_IN_FAILURE";
export const AUTH_GO_SIGN_IN_SUCCESS = "AUTH_GO_SIGN_IN_SUCCESS";
export const AUTH_GO_SIGN_IN_FAILURE = "AUTH_GO_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_CHANGE_PASSWORD_PENDING = "AUTH_CHANGE_PASSWORD_PENDING";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAILURE = "AUTH_CHANGE_PASSWORD_FAILURE";
export const AUTH_RESET_PASSWORD_PENDING = "AUTH_RESET_PASSWORD_PENDING";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const AUTH_FETCH_MANAGED_USERS_PENDING =
  "AUTH_FETCH_MANAGED_USERS_PENDING";
export const AUTH_FETCH_MANAGED_USERS_SUCCESS =
  "AUTH_FETCH_MANAGED_USERS_SUCCESS";
export const AUTH_FETCH_MANAGED_USERS_FAILURE =
  "AUTH_FETCH_MANAGED_USERS_FAILURE";

// InstD
export const FETCH_SPCKINSTRUMENTD_PERNDING = "FETCH_SPCKINSTRUMENTD_PERNDING";
export const FETCH_SPCKINSTRUMENTD_SUCCESS = "FETCH_SPCKINSTRUMENTD_SUCCESS";
export const FETCH_SPCKINSTRUMENTD_ERROR = "FETCH_SPCKINSTRUMENTD_ERROR";

export const FETCH_INSTRUMENTD_PERNDING = "FETCH_INSTRUMENTD_PERNDING";
export const FETCH_INSTRUMENTD_SUCCESS = "FETCH_INSTRUMENTD_SUCCESS";
export const FETCH_INSTRUMENTD_ERROR = "FETCH_INSTRUMENTD_ERROR";
export const FETCH_INSTRUMENT_BY_CATEGORY_PENDING =
  "FETCH_INSTRUMENT_BY_CATEGORY_PENDING";
export const FETCH_INSTRUMENT_BY_CATEGORY_SUCCESS =
  "FETCH_INSTRUMENT_BY_CATEGORY_SUCCESS";
export const FETCH_INSTRUMENT_BY_CATEGORY_ERROR =
  "FETCH_INSTRUMENT_BY_CATEGORY_ERROR";
export const FETCH_INSTRUMENT_TYPE_PENDING = "FETCH_INSTRUMENT_TYPE_PENDING";
export const FETCH_INSTRUMENT_TYPE_SUCCESS = "FETCH_INSTRUMENT_TYPE_SUCCESS";
export const FETCH_INSTRUMENT_TYPE_ERROR = "FETCH_INSTRUMENT_TYPE_ERROR";
export const INSTRUMENT_CHART_PARAM_UPDATE_PENDING =
  "INSTRUMENT_CHART_PARAM_UPDATE_PENDING";
export const INSTRUMENT_CHART_PARAM_UPDATE_SUCCESS =
  "INSTRUMENT_CHART_PARAM_UPDATE_SUCCESS";
export const INSTRUMENT_CHART_PARAM_UPDATE_ERROR =
  "INSTRUMENT_CHART_PARAM_UPDATE_ERROR";
export const SET_INSTRUMENTD_UPDATE_PENDING = "SET_INSTRUMENTD_UPDATE_PENDING";
export const SET_INSTRUMENTD_UPDATE_SUCCESS = "SET_INSTRUMENTD_UPDATE_SUCCESS";
export const SET_INSTRUMENTD_UPDATE_ERROR = "SET_INSTRUMENTD_UPDATE_ERROR";
export const SET_INSTRUMENTD_UPDATES_PENDING =
  "SET_INSTRUMENTD_UPDATES_PENDING";
export const SET_INSTRUMENTD_UPDATES_SUCCESS =
  "SET_INSTRUMENTD_UPDATES_SUCCESS";
export const SET_INSTRUMENTD_UPDATES_ERROR = "SET_INSTRUMENTD_UPDATES_ERROR";
export const SET_INSTRUMENTD_INSERT_PENDING = "SET_INSTRUMENTD_INSERT_PENDING";
export const SET_INSTRUMENTD_INSERT_SUCCESS = "SET_INSTRUMENTD_INSERT_SUCCESS";
export const SET_INSTRUMENTD_INSERT_ERROR = "SET_INSTRUMENTD_INSERT_ERROR";
export const FETCH_UNCONFIGURED_INSTRUMENTS_PENDING =
  "FETCH_UNCONFIGURED_INSTRUMENTS_PERNDING";
export const FETCH_UNCONFIGURED_INSTRUMENTS_SUCCESS =
  "FETCH_UNCONFIGURED_INSTRUMENTS_SUCCESS";
export const FETCH_UNCONFIGURED_INSTRUMENTS_ERROR =
  "FETCH_UNCONFIGURED_INSTRUMENTS_ERROR";
//Enterprise
export const FETCH_ENTERPRISE_PENDING = "FETCH_ENTERPRISE_PENDING";
export const FETCH_ENTERPRISE_SUCCESS = "FETCH_ENTERPRISE_SUCCESS";
export const FETCH_ENTERPRISE_ERROR = "FETCH_ENTERPRISE_ERROR";
export const INSERT_ENTERPRISE_PENDING = "INSERT_ENTERPRISE_PENDING";
export const INSERT_ENTERPRISE_SUCCESS = "INSERT_ENTERPRISE_SUCCESS";
export const INSERT_ENTERPRISE_ERROR = "INSERT_ENTERPRISE_ERROR";
export const UPDATE_ENTERPRISE_PENDING = "UPDATE_ENTERPRISE_PENDING";
export const UPDATE_ENTERPRISE_SUCCESS = "UPDATE_ENTERPRISE_SUCCESS";
export const UPDATE_ENTERPRISE_ERROR = "UPDATE_ENTERPRISE_ERROR";

//Site
export const FETCH_SITE_PENDING = "FETCH_SITE_PENDING";
export const FETCH_SITE_SUCCESS = "FETCH_SITE_SUCCESS";
export const FETCH_SITE_ERROR = "FETCH_SITE_ERROR";
export const INSERT_SITE_PENDING = "INSERT_SITE_PENDING";
export const INSERT_SITE_SUCCESS = "INSERT_SITE_SUCCESS";
export const INSERT_SITE_ERROR = "INSERT_SITE_ERROR";
export const UPDATE_SITE_PENDING = "UPDATE_SITE_PENDING";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_ERROR = "UPDATE_SITE_ERROR";
//Location
export const FETCH_LOCATION_PENDING = "FETCH_LOCATION_PENDING";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_ERROR = "FETCH_LOCATION_ERROR";
export const INSERT_LOCATION_PENDING = "INSERT_LOCATION_PENDING";
export const INSERT_LOCATION_SUCCESS = "INSERT_LOCATION_SUCCESS";
export const INSERT_LOCATION_ERROR = "INSERT_LOCATION_ERROR";
export const UPDATE_LOCATION_PENDING = "UPDATE_LOCATION_PENDING";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";
//Instrument Measurements
export const FETCH_INSTRUMENT_MEASUREMENT_PERNDING =
  "FETCH_INSTRUMENT_MEASUREMENT_PERNDING";
export const FETCH_INSTRUMENT_MEASUREMENT_SUCESS =
  "FETCH_INSTRUMENT_MEASUREMENT_SUCESS";
export const FETCH_INSTRUMENT_MEASUREMENT_ERROR =
  "FETCH_INSTRUMENT_MEASUREMENT_ERROR";
//Category
export const FETCH_CATEGORY_PENDING = "FETCH_CATEGORY_PENDING";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_ERROR = "FETCH_CATEGORY_ERROR";
export const FETCH_CATEGORIES_PENDING = "FETCH_CATEGORIES_PENDING";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";
export const UPDATE_CATEGORY_PENDING = "UPDATE_CATEGORY_PENDING";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const INSERT_CATEGORY_PENDING = "INSERT_CATEGORY_PENDING";
export const INSERT_CATEGORY_SUCCESS = "INSERT_CATEGORY_SUCCESS";
export const INSERT_CATEGORY_ERROR = "INSERT_CATEGORY_ERROR";
export const UPDATE_CATEGORY_CLASSIFICATION_PENDING =
  "UPDATE_CATEGORY_CLASSIFICATION_PENDING";
export const UPDATE_CATEGORY_CLASSIFICATION_SUCCESS =
  "UPDATE_CATEGORY_CLASSIFICATION_SUCCESS";
export const UPDATE_CATEGORY_CLASSIFICATION_ERROR =
  "UPDATE_CATEGORY_CLASSIFICATION_ERROR";

//Events
export const FETCH_EVENTS_PENDING = "FETCH_EVENTS_PENDING";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_ERROR = "FETCH_EVENTS_ERROR";
export const FETCH_EVENT_PENDING = "FETCH_EVENT_PENDING";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_ERROR = "FETCH_EVENT_ERROR";
export const UPDATE_EVENT_PENDING = "UPDATE_EVENT_PENDING";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";
export const INSERT_EVENT_PENDING = "INSERT_EVENT_PENDING";
export const INSERT_EVENT_SUCCESS = "INSERT_EVENT_SUCCESS";
export const INSERT_EVENT_ERROR = "INSERT_EVENT_ERROR";

//Types
export const FETCH_TYPES_PENDING = "FETCH_TYPES_PENDING";
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";
export const FETCH_TYPES_ERROR = "FETCH_TYPES_ERROR";
export const FETCH_ATTRIBUTE_TYPES_PENDING = "FETCH_ATTRIBUTE_TYPES_PENDING";
export const FETCH_ATTRIBUTE_TYPES_SUCCESS = "FETCH_ATTRIBUTE_TYPES_SUCCESS";
export const FETCH_ATTRIBUTE_TYPES_ERROR = "FETCH_ATTRIBUTE_TYPES_ERROR";

export const FETCH_TYPE_PENDING = "FETCH_TYPE_PENDING";
export const FETCH_TYPE_SUCCESS = "FETCH_TYPE_SUCCESS";
export const FETCH_TYPE_ERROR = "FETCH_TYPE_ERROR";
export const UPDATE_TYPE_PENDING = "UPDATE_TYPE_PENDING";
export const UPDATE_TYPE_SUCCESS = "UPDATE_TYPE_SUCCESS";
export const UPDATE_TYPE_ERROR = "UPDATE_TYPE_ERROR";
export const INSERT_TYPE_PENDING = "INSERT_TYPE_PENDING";
export const INSERT_TYPE_SUCCESS = "INSERT_TYPE_SUCCESS";
export const INSERT_TYPE_ERROR = "INSERT_TYPE_ERROR";

//Classification
export const FETCH_CLASSIFICATIONS_PENDING = "FETCH_CLASSIFICATIONS_PENDING";
export const FETCH_CLASSIFICATIONS_SUCCESS = "FETCH_CLASSIFICATIONS_SUCCESS";
export const FETCH_CLASSIFICATIONS_ERROR = "FETCH_CLASSIFICATIONS_ERROR";
export const FETCH_CLASSIFICATION_PENDING = "FETCH_CLASSIFICATION_PENDING";
export const FETCH_CLASSIFICATION_SUCCESS = "FETCH_CLASSIFICATION_SUCCESS";
export const FETCH_CLASSIFICATION_ERROR = "FETCH_CLASSIFICATION_ERROR";
export const UPDATE_CLASSIFICATION_PENDING = "UPDATE_CLASSIFICATION_PENDING";
export const UPDATE_CLASSIFICATION_SUCCESS = "UPDATE_CLASSIFICATION_SUCCESS";
export const UPDATE_CLASSIFICATION_ERROR = "UPDATE_CLASSIFICATION_ERROR";
export const INSERT_CLASSIFICATION_PENDING = "INSERT_CLASSIFICATION_PENDING";
export const INSERT_CLASSIFICATION_SUCCESS = "INSERT_CLASSIFICATION_SUCCESS";
export const INSERT_CLASSIFICATION_ERROR = "INSERT_CLASSIFICATION_ERROR";

//Attribute
export const FETCH_ATTRIBUTES_PENDING = "FETCH_ATTRIBUTES_PENDING";
export const FETCH_ATTRIBUTES_SUCCESS = "FETCH_ATTRIBUTES_SUCCESS";
export const FETCH_ATTRIBUTES_ERROR = "FETCH_ATTRIBUTES_ERROR";
export const FETCH_ATTRIBUTE_PENDING = "FETCH_ATTRIBUTE_PENDING";
export const FETCH_ATTRIBUTE_SUCCESS = "FETCH_ATTRIBUTE_SUCCESS";
export const FETCH_ATTRIBUTE_ERROR = "FETCH_ATTRIBUTE_ERROR";
export const UPDATE_ATTRIBUTE_PENDING = "UPDATE_ATTRIBUTE_PENDING";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_ERROR = "UPDATE_ATTRIBUTE_ERROR";
export const INSERT_ATTRIBUTE_PENDING = "INSERT_ATTRIBUTE_PENDING";
export const INSERT_ATTRIBUTE_SUCCESS = "INSERT_ATTRIBUTE_SUCCESS";
export const INSERT_ATTRIBUTE_ERROR = "INSERT_ATTRIBUTE_ERROR";
export const REMOVE_ATTRIBUTE_PENDING = "REMOVE_ATTRIBUTE_PENDING";
export const REMOVE_ATTRIBUTE_SUCCESS = "REMOVE_ATTRIBUTE_SUCCESS";
export const REMOVE_ATTRIBUTE_ERROR = "REMOVE_ATTRIBUTE_ERROR";

// System Alarm
export const FETCH_SYSTEM_ALARM_PENDING = "FETCH_SYSTEM_ALARM_PENDING";
export const FETCH_SYSTEM_ALARM_SUCCESS = "FETCH_SYSTEM_ALARM_SUCCESS";
export const FETCH_SYSTEM_ALARM_ERROR = "FETCH_SYSTEM_ALARM_ERROR";

//Alarm
export const FETCH_ALARMS_REPORTS_PENDING = "FETCH_ALARMS_REPORTS_PENDING";
export const FETCH_ALARMS_REPORTS_SUCCESS = "FETCH_ALARMS_REPORTS_SUCCESS";
export const FETCH_ALARMS_REPORTS_ERROR = "FETCH_ALARMS_REPORTS_ERROR";
export const FETCH_ALARMS_PENDING = "FETCH_ALARMS_PENDING";
export const FETCH_ALARMS_SUCCESS = "FETCH_ALARMS_SUCCESS";
export const FETCH_ALARMS_ERROR = "FETCH_ALARMS_ERROR";
export const FETCH_ALARM_PENDING = "FETCH_ALARM_PENDING";
export const FETCH_ALARM_SUCCESS = "FETCH_ALARM_SUCCESS";
export const FETCH_ALARM_ERROR = "FETCH_ALARM_ERROR";
export const FETCH_ALARM_CONDITIONS_PENDING = "FETCH_ALARM_CONDITIONS_PENDING";
export const FETCH_ALARM_CONDITIONS_SUCCESS = "FETCH_ALARM_CONDITIONS_SUCCESS";
export const FETCH_ALARM_CONDITIONS_ERROR = "FETCH_ALARM_CONDITIONS_ERROR";
export const UPDATE_ALARM_PENDING = "UPDATE_ALARM_PENDING";
export const UPDATE_ALARM_SUCCESS = "UPDATE_ALARM_SUCCESS";
export const UPDATE_ALARM_ERROR = "UPDATE_ALARM_ERROR";
export const INSERT_ALARM_PENDING = "INSERT_ALARM_PENDING";
export const INSERT_ALARM_SUCCESS = "INSERT_ALARM_SUCCESS";
export const INSERT_ALARM_ERROR = "INSERT_ALARM_ERROR";
export const REMOVE_ALARM_PENDING = "REMOVE_ALARM_PENDING";
export const REMOVE_ALARM_SUCCESS = "REMOVE_ALARM_SUCCESS";
export const REMOVE_ALARM_ERROR = "REMOVE_ALARM_ERROR";

export const INSERT_ALARM_CONDITION_PENDING = "INSERT_ALARM_CONDITION_PENDING";
export const INSERT_ALARM_CONDITION_SUCCESS = "INSERT_ALARM_CONDITION_SUCCESS";
export const INSERT_ALARM_CONDITION_ERROR = "INSERT_ALARM_CONDITION_ERROR";

//Measurement Types
export const FETCH_MEASUREMENT_TYPES_PENDING =
  "FETCH_MEASUREMENT_TYPES_PENDING";
export const FETCH_MEASUREMENT_TYPES_SUCCESS =
  "FETCH_MEASUREMENT_TYPES_SUCCESS";
export const FETCH_MEASUREMENT_TYPES_ERROR = "FETCH_MEASUREMENT_TYPES_ERROR";
export const FETCH_MEASUREMENT_TYPE_PENDING = "FETCH_MEASUREMENT_TYPE_PENDING";
export const FETCH_MEASUREMENT_TYPE_SUCCESS = "FETCH_MEASUREMENT_TYPE_SUCCESS";
export const FETCH_MEASUREMENT_TYPE_ERROR = "FETCH_MEASUREMENT_TYPE_ERROR";
export const UPDATE_MEASUREMENT_TYPE_PENDING =
  "UPDATE_MEASUREMENT_TYPE_PENDING";
export const UPDATE_MEASUREMENT_TYPE_SUCCESS =
  "UPDATE_MEASUREMENT_TYPE_SUCCESS";
export const UPDATE_MEASUREMENT_TYPE_ERROR = "UPDATE_MEASUREMENT_TYPE_ERROR";
export const INSERT_MEASUREMENT_TYPE_PENDING =
  "INSERT_MEASUREMENT_TYPE_PENDING";
export const INSERT_MEASUREMENT_TYPE_SUCCESS =
  "INSERT_MEASUREMENT_TYPE_SUCCESS";
export const INSERT_MEASUREMENT_TYPE_ERROR = "INSERT_MEASUREMENT_TYPE_ERROR";
export const REMOVE_MEASUREMENT_TYPE_PENDING =
  "REMOVE_MEASUREMENT_TYPE_PENDING";
export const REMOVE_MEASUREMENT_TYPE_SUCCESS =
  "REMOVE_MEASUREMENT_TYPE_SUCCESS";
export const REMOVE_MEASUREMENT_TYPE_ERROR = "REMOVE_MEASUREMENT_TYPE_ERROR";

//Measurement Types
export const FETCH_OPERATORS_PENDING = "FETCH_OPERATORS_PENDING";
export const FETCH_OPERATORS_SUCCESS = "FETCH_OPERATORS_SUCCESS";
export const FETCH_OPERATORS_ERROR = "FETCH_OPERATORS_ERROR";
export const FETCH_OPERATOR_PENDING = "FETCH_OPERATOR_PENDING";
export const FETCH_OPERATOR_SUCCESS = "FETCH_OPERATOR_SUCCESS";
export const FETCH_OPERATOR_ERROR = "FETCH_OPERATOR_ERROR";
export const UPDATE_OPERATOR_PENDING = "UPDATE_OPERATOR_PENDING";
export const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS";
export const UPDATE_OPERATOR_ERROR = "UPDATE_OPERATOR_ERROR";
export const INSERT_OPERATOR_PENDING = "INSERT_OPERATOR_PENDING";
export const INSERT_OPERATOR_SUCCESS = "INSERT_OPERATOR_SUCCESS";
export const INSERT_OPERATOR_ERROR = "INSERT_OPERATOR_ERROR";
export const REMOVE_OPERATOR_PENDING = "REMOVE_OPERATOR_PENDING";
export const REMOVE_OPERATOR_SUCCESS = "REMOVE_OPERATOR_SUCCESS";
export const REMOVE_OPERATOR_ERROR = "REMOVE_OPERATOR_ERROR";

//Measurement Types
export const FETCH_DEFAULT_PARAMETERS_PENDING =
  "FETCH_DEFAULT_PARAMETERS_PENDING";
export const FETCH_DEFAULT_PARAMETERS_SUCCESS =
  "FETCH_DEFAULT_PARAMETERS_SUCCESS";
export const FETCH_DEFAULT_PARAMETERS_ERROR = "FETCH_DEFAULT_PARAMETERS_ERROR";
export const FETCH_DEFAULT_PARAMETER_PENDING =
  "FETCH_DEFAULT_PARAMETER_PENDING";
export const FETCH_DEFAULT_PARAMETER_SUCCESS =
  "FETCH_DEFAULT_PARAMETER_SUCCESS";
export const FETCH_DEFAULT_PARAMETER_ERROR = "FETCH_DEFAULT_PARAMETER_ERROR";
export const UPDATE_DEFAULT_PARAMETER_PENDING =
  "UPDATE_DEFAULT_PARAMETER_PENDING";
export const UPDATE_DEFAULT_PARAMETER_SUCCESS =
  "UPDATE_DEFAULT_PARAMETER_SUCCESS";
export const UPDATE_DEFAULT_PARAMETER_ERROR = "UPDATE_DEFAULT_PARAMETER_ERROR";
export const INSERT_DEFAULT_PARAMETER_PENDING =
  "INSERT_DEFAULT_PARAMETER_PENDING";
export const INSERT_DEFAULT_PARAMETER_SUCCESS =
  "INSERT_DEFAULT_PARAMETER_SUCCESS";
export const INSERT_DEFAULT_PARAMETER_ERROR = "INSERT_DEFAULT_PARAMETER_ERROR";
export const REMOVE_DEFAULT_PARAMETER_PENDING =
  "REMOVE_DEFAULT_PARAMETER_PENDING";
export const REMOVE_DEFAULT_PARAMETER_SUCCESS =
  "REMOVE_DEFAULT_PARAMETER_SUCCESS";
export const REMOVE_DEFAULT_PARAMETER_ERROR = "REMOVE_DEFAULT_PARAMETER_ERROR";

//INSERT_INSTRUMENT_CALIBRATION_PENDING
export const INSERT_INSTRUMENT_CALIBRATION_PENDING =
  "INSERT_INSTRUMENT_CALIBRATION_PENDING";
export const INSERT_INSTRUMENT_CALIBRATION_SUCCESS =
  "INSERT_INSTRUMENT_CALIBRATION_SUCCESS";
export const INSERT_INSTRUMENT_CALIBRATION_ERROR =
  "INSERT_INSTRUMENT_CALIBRATION_ERROR";
export const FETCH_INSTRUMENT_TO_CALIBRATE_PENDING =
  "FETCH_INSTRUMENT_TO_CALIBRATE_PENDING";
export const FETCH_INSTRUMENT_TO_CALIBRATE_SUCCESS =
  "FETCH_INSTRUMENT_TO_CALIBRATE_SUCCESS";
export const FETCH_INSTRUMENT_TO_CALIBRATE_ERROR =
  "FETCH_INSTRUMENT_TO_CALIBRATE_ERROR";

export const UPDATE_INSTRUMENT_BATTERY_PARAMETERS_PENDING =
  "UPDATE_INSTRUMENT_BATTERY_PARAMETERS_PENDING";
export const UPDATE_INSTRUMENT_BATTERY_PARAMETERS_SUCCESS =
  "UPDATE_INSTRUMENT_BATTERY_PARAMETERS_SUCCESS";
export const UPDATE_INSTRUMENT_BATTERY_PARAMETERS_ERROR =
  "UPDATE_INSTRUMENT_BATTERY_PARAMETERS_ERROR";

//DEPLOYMENT_TYPE
export const FETCH_DEPLOYMENT_TYPE_PENDING = "FETCH_DEPLOYMENT_TYPE_PENDING";
export const FETCH_DEPLOYMENT_TYPE_SUCCESS = "FETCH_DEPLOYMENT_TYPE_SUCCESS";
export const FETCH_DEPLOYMENT_TYPE_ERROR = "FETCH_DEPLOYMENT_TYPE_ERROR";
export const INSERT_DEPLOYMENT_TYPE_PENDING = "INSERT_DEPLOYMENT_TYPE_PENDING";
export const INSERT_DEPLOYMENT_TYPE_SUCCESS = "INSERT_DEPLOYMENT_TYPE_SUCCESS";
export const INSERT_DEPLOYMENT_TYPE_ERROR = "INSERT_DEPLOYMENT_TYPE_ERROR";
//DEPLOYMENT
export const FETCH_DEPLOYMENT_INSTRUMENTS_PENDING =
  "FETCH_DEPLOYMENT_INSTRUMENTS_PENDING";
export const FETCH_DEPLOYMENT_INSTRUMENTS_SUCCESS =
  "FETCH_DEPLOYMENT_INSTRUMENTS_SUCCESS";
export const FETCH_DEPLOYMENT_INSTRUMENTS_ERROR =
  "FETCH_DEPLOYMENT_INSTRUMENTS_ERROR";

//Deployment
export const INSERT_DEPLOYMENT_PENDING = "INSERT_DEPLOYMENT_PENDING";
export const INSERT_DEPLOYMENT_SUCCESS = "INSERT_DEPLOYMENT_SUCCESS";
export const INSERT_DEPLOYMENT_ERROR = "INSERT_DEPLOYMENT_ERROR";
export const UPDATE_DEPLOYMENT_PENDING = "UPDATE_DEPLOYMENT_PENDING";
export const UPDATE_DEPLOYMENT_SUCCESS = "UPDATE_DEPLOYMENT_SUCCESS";
export const UPDATE_DEPLOYMENT_ERROR = "UPDATE_DEPLOYMENT_ERROR";

//task type
export const FETCH_TASK_TYPE_TEMPLATES_PENDING =
  "FETCH_TASK_TYPE_TEMPLATES_PENDING";
export const FETCH_TASK_TYPE_TEMPLATES_SUCCESS =
  "FETCH_TASK_TYPE_TEMPLATES_SUCCESS";
export const FETCH_TASK_TYPE_TEMPLATES_ERROR =
  "FETCH_TASK_TYPE_TEMPLATES_ERROR";
export const FETCH_TASK_TYPE_TEMPLATE_PENDING =
  "FETCH_TASK_TYPE_TEMPLATE_PENDING";
export const FETCH_TASK_TYPE_TEMPLATE_SUCCESS =
  "FETCH_TASK_TYPE_TEMPLATE_SUCCESS";
export const FETCH_TASK_TYPE_TEMPLATE_ERROR = "FETCH_TASK_TYPE_TEMPLATE_ERROR";
export const UPDATE_TASK_TYPE_TEMPLATE_PENDING =
  "UPDATE_TASK_TYPE_TEMPLATE_PENDING";
export const UPDATE_TASK_TYPE_TEMPLATE_SUCCESS =
  "UPDATE_TASK_TYPE_TEMPLATE_SUCCESS";
export const UPDATE_TASK_TYPE_TEMPLATE_ERROR =
  "UPDATE_TASK_TYPE_TEMPLATE_ERROR";
export const INSERT_TASK_TYPE_TEMPLATE_PENDING =
  "INSERT_TASK_TYPE_TEMPLATE_PENDING";
export const INSERT_TASK_TYPE_TEMPLATE_SUCCESS =
  "INSERT_TASK_TYPE_TEMPLATE_SUCCESS";
export const INSERT_TASK_TYPE_TEMPLATE_ERROR =
  "INSERT_TASK_TYPE_TEMPLATE_ERROR";
export const REMOVE_TASK_TYPE_TEMPLATE_PENDING =
  "REMOVE_TASK_TYPE_TEMPLATE_PENDING";
export const REMOVE_TASK_TYPE_TEMPLATE_SUCCESS =
  "REMOVE_TASK_TYPE_TEMPLATE_SUCCESS";
export const REMOVE_TASK_TYPE_TEMPLATE_ERROR =
  "REMOVE_TASK_TYPE_TEMPLATE_ERROR";

//task
export const FETCH_TASK_TEMPLATES_PENDING = "FETCH_TASK_TEMPLATES_PENDING";
export const FETCH_TASK_TEMPLATES_SUCCESS = "FETCH_TASK_TEMPLATES_SUCCESS";
export const FETCH_TASK_TEMPLATES_ERROR = "FETCH_TASK_TEMPLATES_ERROR";
export const FETCH_TASK_TEMPLATE_PENDING = "FETCH_TASK_TEMPLATE_PENDING";
export const FETCH_TASK_TEMPLATE_SUCCESS = "FETCH_TASK_TEMPLATE_SUCCESS";
export const FETCH_TASK_TEMPLATE_ERROR = "FETCH_TASK_TEMPLATE_ERROR";
export const UPDATE_TASK_TEMPLATE_PENDING = "UPDATE_TASK_TEMPLATE_PENDING";
export const UPDATE_TASK_TEMPLATE_SUCCESS = "UPDATE_TASK_TEMPLATE_SUCCESS";
export const UPDATE_TASK_TEMPLATE_ERROR = "UPDATE_TASK_TEMPLATE_ERROR";
export const INSERT_TASK_TEMPLATE_PENDING = "INSERT_TASK_TEMPLATE_PENDING";
export const INSERT_TASK_TEMPLATE_SUCCESS = "INSERT_TASK_TEMPLATE_SUCCESS";
export const INSERT_TASK_TEMPLATE_ERROR = "INSERT_TASK_TEMPLATE_ERROR";
export const REMOVE_TASK_TEMPLATE_PENDING = "REMOVE_TASK_TEMPLATE_PENDING";
export const REMOVE_TASK_TEMPLATE_SUCCESS = "REMOVE_TASK_TEMPLATE_SUCCESS";
export const REMOVE_TASK_TEMPLATE_ERROR = "REMOVE_TASK_TEMPLATE_ERROR";

//task
export const FETCH_TASKS_PENDING = "FETCH_TASKS_PENDING";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_ERROR = "FETCH_TASKS_ERROR";
export const FETCH_TASK_PENDING = "FETCH_TASK_PENDING";
export const FETCH_TASK_SUCCESS = "FETCH_TASK_SUCCESS";
export const FETCH_TASK_ERROR = "FETCH_TASK_ERROR";
export const UPDATE_TASK_PENDING = "UPDATE_TASK_PENDING";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR";
export const INSERT_TASK_PENDING = "INSERT_TASK_PENDING";
export const INSERT_TASK_SUCCESS = "INSERT_TASK_SUCCESS";
export const INSERT_TASK_ERROR = "INSERT_TASK_ERROR";
export const REMOVE_TASK_PENDING = "REMOVE_TASK_PENDING";
export const REMOVE_TASK_SUCCESS = "REMOVE_TASK_SUCCESS";
export const REMOVE_TASK_ERROR = "REMOVE_TASK_ERROR";

//Checklist
export const FETCH_CHECKLIST_SEARCH_PENDING =
  "FETCH_CHECKLIST_SEARCH_PENDING";
export const FETCH_CHECKLIST_SEARCH_SUCCESS =
  "FETCH_CHECKLIST_SEARCH_SUCCESS";
export const FETCH_CHECKLIST_SEARCH_ERROR = "FETCH_CHECKLIST_SEARCH_ERROR";
export const FETCH_CHECKLIST_REPORTS_PENDING =
  "FETCH_CHECKLISTS_REPORTS_PENDING";
export const FETCH_CHECKLIST_REPORTS_SUCCESS =
  "FETCH_CHECKLISTS_REPORTS_SUCCESS";
export const FETCH_CHECKLIST_REPORTS_ERROR = "FETCH_CHECKLISTS_REPORTS_ERROR";
export const FETCH_CHECKLISTS_PENDING = "FETCH_CHECKLISTS_PENDING";
export const FETCH_CHECKLISTS_SUCCESS = "FETCH_CHECKLISTS_SUCCESS";
export const FETCH_CHECKLISTS_ERROR = "FETCH_CHECKLISTS_ERROR";
export const FETCH_CHECKLIST_PENDING = "FETCH_CHECKLIST_PENDING";
export const FETCH_CHECKLIST_SUCCESS = "FETCH_CHECKLIST_SUCCESS";
export const FETCH_CHECKLIST_ERROR = "FETCH_CHECKLIST_ERROR";
export const UPDATE_CHECKLIST_PENDING = "UPDATE_CHECKLIST_PENDING";
export const UPDATE_CHECKLIST_SUCCESS = "UPDATE_CHECKLIST_SUCCESS";
export const UPDATE_CHECKLIST_ERROR = "UPDATE_CHECKLIST_ERROR";
export const INSERT_CHECKLIST_PENDING = "INSERT_CHECKLIST_PENDING";
export const INSERT_CHECKLIST_SUCCESS = "INSERT_CHECKLIST_SUCCESS";
export const INSERT_CHECKLIST_ERROR = "INSERT_CHECKLIST_ERROR";
export const REMOVE_CHECKLIST_PENDING = "REMOVE_CHECKLIST_PENDING";
export const REMOVE_CHECKLIST_SUCCESS = "REMOVE_CHECKLIST_SUCCESS";
export const REMOVE_CHECKLIST_ERROR = "REMOVE_CHECKLIST_ERROR";
export const INSERT_CHECKLIST_SCHEDULE_PENDING =
  "INSERT_CHECKLIST_SCHEDULE_PENDING";
export const INSERT_CHECKLIST_SCHEDULE_SUCCESS =
  "INSERT_CHECKLIST_SCHEDULE_SUCCESS";
export const INSERT_CHECKLIST_SCHEDULE_ERROR =
  "INSERT_CHECKLIST_SCHEDULE_ERROR";
export const UPDATE_CHECKLIST_SCHEDULE_PENDING =
  "UPDATE_CHECKLIST_SCHEDULE_PENDING";
export const UPDATE_CHECKLIST_SCHEDULE_SUCCESS =
  "UPDATE_CHECKLIST_SCHEDULE_SUCCESS";
export const UPDATE_CHECKLIST_SCHEDULE_ERROR =
  "UPDATE_CHECKLIST_SCHEDULE_ERROR";
export const GET_LAST_LOCATION = "GET_LAST_LOCATION";
export const GET_LAST_LOCATION_ERROR = "GET_LAST_LOCATION_ERROR";

//Checklist Template
export const FETCH_CHECKLISTS_TEMPLATE_PENDING =
  "FETCH_CHECKLISTS_TEMPLATE_PENDING";
export const FETCH_CHECKLISTS_TEMPLATE_SUCCESS =
  "FETCH_CHECKLISTS_TEMPLATE_SUCCESS";
export const FETCH_CHECKLISTS_TEMPLATE_ERROR =
  "FETCH_CHECKLISTS_TEMPLATE_ERROR";
export const FETCH_CHECKLIST_TEMPLATE_PENDING =
  "FETCH_CHECKLIST_TEMPLATE_PENDING";
export const FETCH_CHECKLIST_TEMPLATE_SUCCESS =
  "FETCH_CHECKLIST_TEMPLATE_SUCCESS";
export const FETCH_CHECKLIST_TEMPLATE_ERROR = "FETCH_CHECKLIST_TEMPLATE_ERROR";
export const UPDATE_CHECKLIST_TEMPLATE_PENDING =
  "UPDATE_CHECKLIST_TEMPLATE_PENDING";
export const UPDATE_CHECKLIST_TEMPLATE_SUCCESS =
  "UPDATE_CHECKLIST_TEMPLATE_SUCCESS";
export const UPDATE_CHECKLIST_TEMPLATE_ERROR =
  "UPDATE_CHECKLIST_TEMPLATE_ERROR";
export const INSERT_CHECKLIST_TEMPLATE_PENDING =
  "INSERT_CHECKLIST_TEMPLATE_PENDING";
export const INSERT_CHECKLIST_TEMPLATE_SUCCESS =
  "INSERT_CHECKLIST_TEMPLATE_SUCCESS";
export const INSERT_CHECKLIST_TEMPLATE_ERROR =
  "INSERT_CHECKLIST_TEMPLATE_ERROR";
export const REMOVE_CHECKLIST_TEMPLATE_PENDING =
  "REMOVE_CHECKLIST_TEMPLATE_PENDING";
export const REMOVE_CHECKLIST_TEMPLATE_SUCCESS =
  "REMOVE_CHECKLIST_TEMPLATE_SUCCESS";
export const REMOVE_CHECKLIST_TEMPLATE_ERROR =
  "REMOVE_CHECKLIST_TEMPLATE_ERROR";
export const UPDATE_CHECKLIST_SELECTED_USER_PENDING =
  "UPDATE_CHECKLIST_SELECTED_USER_PENDING";
export const UPDATE_CHECKLIST_SELECTED_USER_SUCCESS =
  "UPDATE_CHECKLIST_SELECTED_USER_SUCCESS";
export const UPDATE_CHECKLIST_SELECTED_USER_ERROR =
  "UPDATE_CHECKLIST_SELECTED_USER_ERROR";

//Checklist Task Template
export const FETCH_CHECKLIST_TASKS_TEMPLATE_PENDING =
  "FETCH_CHECKLIST_TASKS_TEMPLATE_PENDING";
export const FETCH_CHECKLIST_TASKS_TEMPLATE_SUCCESS =
  "FETCH_CHECKLIST_TASKS_TEMPLATE_SUCCESS";
export const FETCH_CHECKLIST_TASKS_TEMPLATE_ERROR =
  "FETCH_CHECKLIST_TASKS_TEMPLATE_ERROR";
export const FETCH_CHECKLIST_TASK_TEMPLATE_PENDING =
  "FETCH_CHECKLIST_TASK_TEMPLATE_PENDING";
export const FETCH_CHECKLIST_TASK_TEMPLATE_SUCCESS =
  "FETCH_CHECKLIST_TASK_TEMPLATE_SUCCESS";
export const FETCH_CHECKLIST_TASK_TEMPLATE_ERROR =
  "FETCH_CHECKLIST_TASK_TEMPLATE_ERROR";
export const UPDATE_CHECKLIST_TASK_TEMPLATE_PENDING =
  "UPDATE_CHECKLIST_TASK_TEMPLATE_PENDING";
export const UPDATE_CHECKLIST_TASK_TEMPLATE_SUCCESS =
  "UPDATE_CHECKLIST_TASK_TEMPLATE_SUCCESS";
export const UPDATE_CHECKLIST_TASK_TEMPLATE_ERROR =
  "UPDATE_CHECKLIST_TASK_TEMPLATE_ERROR";
export const INSERT_CHECKLIST_TASK_TEMPLATE_PENDING =
  "INSERT_CHECKLIST_TASK_TEMPLATE_PENDING";
export const INSERT_CHECKLIST_TASK_TEMPLATE_SUCCESS =
  "INSERT_CHECKLIST_TASK_TEMPLATE_SUCCESS";
export const INSERT_CHECKLIST_TASK_TEMPLATE_ERROR =
  "INSERT_CHECKLIST_TASK_TEMPLATE_ERROR";
export const REMOVE_CHECKLIST_TASK_TEMPLATE_PENDING =
  "REMOVE_CHECKLIST_TASK_TEMPLATE_PENDING";
export const REMOVE_CHECKLIST_TASK_TEMPLATE_SUCCESS =
  "REMOVE_CHECKLIST_TASK_TEMPLATE_SUCCESS";
export const REMOVE_CHECKLIST_TASK_TEMPLATE_ERROR =
  "REMOVE_CHECKLIST_TASK_TEMPLATE_ERROR";

export const FETCH_CHECKLIST_USER_PENDING = "FETCH_CHECKLIST_USER_PENDING";
export const FETCH_CHECKLIST_USER_SUCCESS = "FETCH_CHECKLIST_USER_SUCCESS";
export const FETCH_CHECKLIST_USER_ERROR = "FETCH_CHECKLIST_USER_ERROR";
export const INSERT_CHECKLIST_USER_PENDING = "INSERT_CHECKLIST_USER_PENDING";
export const INSERT_CHECKLIST_USER_SUCCESS = "INSERT_CHECKLIST_USER_SUCCESS";
export const INSERT_CHECKLIST_USER_ERROR = "INSERT_CHECKLIST_USER_ERROR";
export const UPDATE_CHECKLIST_USER_PENDING = "UPDATE_CHECKLIST_USER_PENDING";
export const UPDATE_CHECKLIST_USER_SUCCESS = "UPDATE_CHECKLIST_USER_SUCCESS";
export const UPDATE_CHECKLIST_USER_ERROR = "UPDATE_CHECKLIST_USER_ERROR";
export const DELETE_CHECKLIST_USER_PENDING = "DELETE_CHECKLIST_USER_PENDING";
export const DELETE_CHECKLIST_USER_SUCCESS = "DELETE_CHECKLIST_USER_SUCCESS";
export const DELETE_CHECKLIST_USER_ERROR = "DELETE_CHECKLIST_USER_ERROR";

//ToolboxTalk
export const FETCH_TOOLBOX_PENDING = "FETCH_TOOLBOX_PENDING";
export const FETCH_TOOLBOX_SUCCESS = "FETCH_TOOLBOX_SUCCESS";
export const FETCH_TOOLBOX_ERROR = "FETCH_TOOLBOX_ERROR";
export const INSERT_TOOLBOX_PENDING = "INSERT_TOOLBOX_PENDING";
export const INSERT_TOOLBOX_SUCCESS = "INSERT_TOOLBOX_SUCCESS";
export const INSERT_TOOLBOX_ERROR = "INSERT_TOOLBOX_ERROR";
export const UPDATE_TOOLBOX_PENDING = "UPDATE_TOOLBOX_PENDING";
export const UPDATE_TOOLBOX_SUCCESS = "UPDATE_TOOLBOX_SUCCESS";
export const UPDATE_TOOLBOX_ERROR = "UPDATE_TOOLBOX_ERROR";
export const FETCH_TOOLBOX_USER_PENDING = "FETCH_TOOLBOX_USER_PENDING";
export const FETCH_TOOLBOX_USER_SUCCESS = "FETCH_TOOLBOX_USER_SUCCESS";
export const FETCH_TOOLBOX_USER_ERROR = "FETCH_TOOLBOX_USER_ERROR";
export const INSERT_TOOLBOX_USER_PENDING = "INSERT_TOOLBOX_USER_PENDING";
export const INSERT_TOOLBOX_USER_SUCCESS = "INSERT_TOOLBOX_USER_SUCCESS";
export const INSERT_TOOLBOX_USER_ERROR = "INSERT_TOOLBOX_USER_ERROR";
export const UPDATE_TOOLBOX_USER_PENDING = "UPDATE_TOOLBOX_USER_PENDING";
export const UPDATE_TOOLBOX_USER_SUCCESS = "UPDATE_TOOLBOX_USER_SUCCESS";
export const UPDATE_TOOLBOX_USER_ERROR = "UPDATE_TOOLBOX_USER_ERROR";
export const DELETE_TOOLBOX_USER_PENDING = "DELETE_TOOLBOX_USER_PENDING";
export const DELETE_TOOLBOX_USER_SUCCESS = "DELETE_TOOLBOX_USER_SUCCESS";
export const DELETE_TOOLBOX_USER_ERROR = "DELETE_TOOLBOX_USER_ERROR";

//TSI
export const FETCH_TSI_EVENTS_PENDING = "FETCH_TSI_EVENTS_PENDING";
export const FETCH_TSI_EVENTS_SUCCESS = "FETCH_TSI_EVENTS_SUCCESS";
export const FETCH_TSI_EVENTS_ERROR = "FETCH_TSI_EVENTS_ERROR";
export const FETCH_TSI_SERIES_PENDING = "FETCH_TSI_SERIES_PENDING";
export const FETCH_TSI_SERIES_SUCCESS = "FETCH_TSI_SERIES_SUCCESS";
export const FETCH_TSI_SERIES_ERROR = "FETCH_TSI_SERIES_ERROR";
export const FETCH_TSI_AGGREGATE_PENDING = "FETCH_TSI_AGGREGATE_PENDING";
export const FETCH_TSI_AGGREGATE_SUCCESS = "FETCH_TSI_AGGREGATE_SUCCESS";
export const FETCH_TSI_AGGREGATE_ERROR = "FETCH_TSI_AGGREGATE_ERROR";
export const FETCH_TSI_INSTANCES_PENDING = "FETCH_TSI_INSTANCES_PENDING";
export const FETCH_TSI_INSTANCES_SUCCESS = "FETCH_TSI_INSTANCES_SUCCESS";
export const FETCH_TSI_INSTANCES_ERROR = "FETCH_TSI_INSTANCES_ERROR";
export const FETCH_IM_AGGREGATE_PENDING = "FETCH_IM_AGGREGATE_PENDING";
export const FETCH_IM_AGGREGATE_SUCCESS = "FETCH_IM_AGGREGATE_SUCCESS";
export const FETCH_IM_AGGREGATE_ERROR = "FETCH_IM_AGGREGATE_ERROR";

//note
export const FETCH_NOTE_PENDING = "FETCH_NOTE_PENDING";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_ERROR = "FETCH_NOTE_ERROR";
export const UPDATE_NOTE_PENDING = "UPDATE_NOTE_PENDING";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_ERROR = "UPDATE_NOTE_ERROR";
export const INSERT_NOTE_PENDING = "INSERT_NOTE_PENDING";
export const INSERT_NOTE_SUCCESS = "INSERT_NOTE_SUCCESS";
export const INSERT_NOTE_ERROR = "INSERT_NOTE_ERROR";
export const REMOVE_NOTE_PENDING = "REMOVE_NOTE_PENDING";
export const REMOVE_NOTE_SUCCESS = "REMOVE_NOTE_SUCCESS";
export const REMOVE_NOTE_ERROR = "REMOVE_NOTE_ERROR";

//Group
export const FETCH_GROUP_PENDING = "FETCH_GROUP_PENDING";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";
export const FETCH_GROUP_ERROR = "FETCH_GROUP_ERROR";
export const INSERT_GROUP_PENDING = "INSERT_GROUP_PENDING";
export const INSERT_GROUP_SUCCESS = "INSERT_GROUP_SUCCESS";
export const INSERT_GROUP_ERROR = "INSERT_GROUP_ERROR";
export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";
export const REMOVE_GROUP_PENDING = "REMOVE_GROUP_PENDING";
export const REMOVE_GROUP_SUCCESS = "REMOVE_GROUP_SUCCESS";
export const REMOVE_GROUP_ERROR = "REMOVE_GROUP_ERROR";

//Group
export const FETCH_GROUP_TYPE_PENDING = "FETCH_GROUP_TYPE_PENDING";
export const FETCH_GROUP_TYPE_SUCCESS = "FETCH_GROUP_TYPE_SUCCESS";
export const FETCH_GROUP_TYPE_ERROR = "FETCH_GROUP_TYPE_ERROR";
export const INSERT_GROUP_TYPE_PENDING = "INSERT_GROUP_TYPE_PENDING";
export const INSERT_GROUP_TYPE_SUCCESS = "INSERT_GROUP_TYPE_SUCCESS";
export const INSERT_GROUP_TYPE_ERROR = "INSERT_GROUP_TYPE_ERROR";
export const UPDATE_GROUP_TYPE_PENDING = "UPDATE_GROUP_TYPE_PENDING";
export const UPDATE_GROUP_TYPE_SUCCESS = "UPDATE_GROUP_TYPE_SUCCESS";
export const UPDATE_GROUP_TYPE_ERROR = "UPDATE_GROUP_TYPE_ERROR";

//Global Variable
export const WEB_API_URL_LOCAL = "https://localhost:44379/";
export const WEB_API_URl_DEVELOPMENT = "https://webapidev.hygeian.online/";
export const WEB_API_URL_PRODUCTION = "https://webapi.hygeian.online/";
export const WEB_URL_LOCAL = "http://localhost:3000/";
export const WEB_URl_DEVELOPMENT = "https://development.hygeian.online/";
export const WEB_URL_PRODUCTION = "https://hygeian.online/";

export const getHostURL = () => {
  if (window.location.hostname === "localhost") return WEB_URL_LOCAL;
  else if (window.location.hostname === "development.hygeian.online")
    return WEB_URl_DEVELOPMENT;
  else return WEB_URL_PRODUCTION;
};

export const getURL = () => {
  if (window.location.hostname === "localhost") return WEB_API_URL_LOCAL;
  else if (window.location.hostname === "development.hygeian.online")
    return WEB_API_URl_DEVELOPMENT;
  else return WEB_API_URL_PRODUCTION;
};

export const getWebTokenURL = () => {
  return window.location.hostname;
};

export const dateFormat = "D MMM YYYY HH:mm:ss";

export const localeDateFormat = "en-AU";

//https://icons8.com/icons/set/excel
export const excelIconUrl =
  "https://img.icons8.com/material-outlined/48/000000/ms-excel--v1.png";
export const pdfIconUrl = "https://img.icons8.com/color/48/null/pdf-2--v1.png";
