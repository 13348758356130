import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.STATES_SELECT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.STATES_SELECT_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.STATES_SELECT_IN_FAILURE:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
