import * as types from "../../constants";
import {
  signGOIn as authGOSignIn,
  signFBIn as authFBSignIn,
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  changePassword as authChangePassword,
  getManagedUsersService,
  getManagedUsersBySiteIdService,
} from "../../services/authService";

export function selectRole(credentials) {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SELECT_IN_SUCCESS,
      data: credentials,
    });
  };
}

export function getManagedUserList() {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_FETCH_MANAGED_USERS_PENDING });
    return getManagedUsersService()
      .then((response) => {
        dispatch({
          type: types.AUTH_FETCH_MANAGED_USERS_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_FETCH_MANAGED_USERS_FAILURE });
        throw error;
      });
  };
}

export function getManagedUserBySiteIdList(siteId) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_FETCH_MANAGED_USERS_PENDING });
    return getManagedUsersBySiteIdService(siteId)
      .then((response) => {
        dispatch({
          type: types.AUTH_FETCH_MANAGED_USERS_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_FETCH_MANAGED_USERS_FAILURE, error });
        throw error;
      });
  };
}

export function signGOIn(credentials) {
  return async (dispatch) => {
    return authGOSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_GO_SIGN_IN_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_GO_SIGN_IN_FAILURE, error });
        throw error;
      });
  };
}

export function signFBIn(credentials) {
  return async (dispatch) => {
    return authFBSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_FB_SIGN_IN_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_FB_SIGN_IN_FAILURE, error });
        throw error;
      });
  };
}

export function signIn(credentials) {
  return async (dispatch) => {
    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE, error });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE, error });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    localStorage.removeItem("Hygeian");
    localStorage.clear();
    setTimeout(() => {
      dispatch({
        type: types.AUTH_SIGN_OUT,
      });
    }, 1000);
  };
}

export function resetPassword(email) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_PENDING });

    return authResetPassword(email)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE, error });
        throw error;
      });
  };
}

export function changePassword(credential) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_PENDING });

    return authChangePassword(credential)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE, error });
        throw error;
      });
  };
}
