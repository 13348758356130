import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    let index = -1;
    arr.map((item) => {
      if (value.alarm.id === item.alarm.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  switch (actions.type) {
    case types.FETCH_MEASUREMENT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
        measurmentType: [],
      };
    case types.FETCH_MEASUREMENT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        measurmentType: actions.payload,
      };
    case types.FETCH_MEASUREMENT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_MEASUREMENT_TYPES_PENDING:
      return {
        ...state,
        pending: true,
        measurementTypes: [],
      };
    case types.FETCH_MEASUREMENT_TYPES_SUCCESS:
      return {
        ...state,
        pending: false,
        measurementTypes: actions.payload,
      };
    case types.FETCH_MEASUREMENT_TYPES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_MEASUREMENT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_MEASUREMENT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        measurementTypes: state.measurementTypes.map((item) => {
          if (actions.payload.data.value.id === item.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_MEASUREMENT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_MEASUREMENT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_MEASUREMENT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        measurementTypes: [
          ...state.measurementTypes,
          actions.payload.data.value,
        ],
        // actions.payload,
      };
    case types.INSERT_MEASUREMENT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    //TODO: remove attribute
    //removeAttributeFromArray
    case types.REMOVE_MEASUREMENT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_MEASUREMENT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        measurementTypes: removeItemFromArray(
          state.measurementTypes,
          actions.payload.data.value
        ),
      };
    case types.REMOVE_MEASUREMENT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
