import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_INSTRUMENT_MEASUREMENT_PERNDING:
      return {
        ...state,
        pending: true,
        //instrumentMeasurement: [],
      };
    case types.FETCH_INSTRUMENT_MEASUREMENT_SUCESS:
      //console.log(actions);
      // if (state.instrumentMeasurement != undefined) {
      //   return {
      //     ...state,
      //     //instrumentMeasurement: sortedInstruments,
      //     //instrumentMeasurement: sortOrder(actions.payload.data),
      //     instrumentMeasurement: state.instrumentMeasurement.map((item) => {
      //       actions.payload.data.map((inst) => {
      //         if (inst.instrumentSerialNumber === item.instrumentSerialNumber) {
      //           item = { ...inst };
      //         }
      //         return inst;
      //       });
      //       return item;
      //     }),
      //     pending: false,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     //instrumentMeasurement: sortedInstruments,
      //     instrumentMeasurement: sortOrder(actions.payload.data),
      //     //instrumentMeasurement: actions.payload.data,
      //     pending: false,
      //   };
      // }
      return {
        ...state,
        //instrumentMeasurement: sortedInstruments,
        instrumentMeasurement: sortOrder(actions.payload.data),
        //instrumentMeasurement: actions.payload.data,
        pending: false,
      };

    /*instrumentMeasurement: state.instrumentMeasurement.map((item) => {
          if (actions.payload.data.instdid === item.instdid) {
            measurementExists = true;
            item = { ...actions.payload.data };
          } else if (!measurementExists) {
          }
          return item;
        }),
      };*/
    case types.FETCH_INSTRUMENT_MEASUREMENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_IM_AGGREGATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_IM_AGGREGATE_SUCCESS:
      return {
        ...state,
        pending: false,
        instrumentMeasurementAggregate: actions.payload.data,
      };
    default:
      return state;
  }
}

function sortOrder(data) {
  let mapped = data.map(function (el, i) {
    return { index: i, value: el.instrumentSerialNumber };
  });

  // sorting the mapped array containing the reduced values
  mapped.sort(function (a, b) {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });

  // container for the resulting order
  let sortedInstruments = mapped.map(function (el) {
    return data[el.index];
  });

  return sortedInstruments;
}

/*
function updateMeasurement(state, data) {
  let measurement = state;
  let measurementExists = false;
  try {
    measurement.instrumentMeasurement.map((item) => {
      if (data.instdid === item.instdid) {
        measurementExists = true;
        item = { data };
      }
      measurement = [...item];
      return item;
    });
  } catch (e) {}

  if (measurementExists) {
    measurement = [measurement.instrumentMeasurement, data];
  }
  return measurement;
}*/
