import React from "react";
import { connect } from "react-redux";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import styled from "styled-components/macro";
import { getter } from "@progress/kendo-react-common";
import { changePassword } from "./../../redux/actions/authActions";
import { InputText, InputPassword } from "./../../components/Input";
import { Alert } from "@material-ui/lab";
import { Paper, Typography } from "@material-ui/core";
import { pwdRegex } from "./../../constants/regexp";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const fnValidator = (value) => (!value ? "Fistname must be provided." : "");
const lnValidator = (value) => (!value ? "Lastname must be provided." : "");
const pwdGetter = getter("password");
const conPWDGetter = getter("conpassword");
const comparePWDValidator = (values) => {
  if (pwdGetter(values) == "" && conPWDGetter(values) == "")
    return {
      password: "Password must be provided.",
      conpassword: "Confirm Password must be provided.",
    };
  if (
    pwdGetter(values) != "" &&
    conPWDGetter(values) != "" &&
    pwdGetter(values) == conPWDGetter(values) &&
    pwdRegex.test(pwdGetter(values)) &&
    pwdRegex.test(conPWDGetter(values))
  )
    return;
  if (pwdGetter(values) != "" && !pwdRegex.test(pwdGetter(values)))
    return {
      password: "Password is not secure.",
    };

  if (conPWDGetter(values) != "" && !pwdRegex.test(conPWDGetter(values)))
    return {
      conpassword: "Password is not secure.",
    };

  if (pwdGetter(values) != conPWDGetter(values))
    if (pwdGetter(values) == "") {
      return {
        password: "Password and confirm password are not matched.",
      };
    } else {
      return {
        conpassword: "Password and confirm password are not matched.",
      };
    }

  return;
};

class ConPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
    };
  }

  componentDidMount() {
    let raws = window.location.href.split("?");
    if (raws.length != 2) window.location.href = "/auth/sign-in";
    let temp = raws[1].split("&");
    if (temp.length != 2) window.location.href = "/auth/sign-in";
    let email = temp[0].split("=")[1];
    let token = temp[1].split("=")[1];
    let data = { email: email, token: token };
    //this.props.responseInvite(data);
    setTimeout(() => {
      this.setState({
        email: data.email,
        token: data.token,
      });
    }, 1000);
  }

  componentWillUnmount() {}

  handleSubmit = (event) => {
    let data = {
      email: this.state.email,
      token: this.state.token,
      password: event.password,
    };
    this.props.changePassword(data);
  };

  handleSignIn = () => {
    window.location.href = "/auth/sign-in";
  };

  render() {
    let content = (
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Form
          onSubmit={this.handleSubmit}
          validator={comparePWDValidator}
          initialValues={{
            password: "",
            conpassword: "",
          }}
          render={(formRenderProps) => (
            <FormElement
              style={{
                width: 500,
              }}
            >
              <div style={{ width: "500px" }}>
                <small>
                  <i>
                    Password must contain at least 1 uppercase, 1 lowercase, 1
                    numeric, 1 special characters and between 8 - 20 characters
                    length :{" "}
                  </i>
                </small>
                <br />
                <br />
              </div>
              <center>
                <table style={{ width: "80%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <label>Update Password</label>
                      </td>
                      <td>:</td>
                      <td>
                        <Field
                          name={"password"}
                          id={"password"}
                          value={""}
                          component={InputPassword}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Confirm Password</label>
                      </td>
                      <td>:</td>
                      <td>
                        <Field
                          name={"conpassword"}
                          id={"conpassword"}
                          component={InputPassword}
                          value={""}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan={3} align={"center"}>
                        <button
                          type={"submit"}
                          className="k-button k-primary"
                          disabled={false}
                        >
                          Submit
                        </button>
                      </td>
                    </tr>
                    {this.props.error &&
                      !this.props.status &&
                      !this.props.pending && (
                        <tr>
                          <td colSpan={3}>
                            <Alert mt={2} mb={1} severity="error">
                              {this.props.error.data}
                            </Alert>
                          </td>
                        </tr>
                      )}
                    {!this.props.pending && this.props.status && (
                      <tr>
                        <td colSpan={3}>
                          <Alert mt={2} mb={1} severity="info">
                            Your password has been changed successfully.
                          </Alert>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </center>
            </FormElement>
          )}
        />{" "}
      </Wrapper>
    );
    return (
      <React.Fragment>
        {content}
        {this.props.pending && (
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pending: state.authReducer.pending,
    status: state.authReducer.status,
    error: state.authReducer.error,
  };
};

const mapDispatchToProps = {
  changePassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConPassword);
