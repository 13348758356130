import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    //console.log(value);
    let index = -1;
    arr.map((item) => {
      if (value.id === item.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  switch (actions.type) {
    case types.FETCH_GROUP_PENDING:
      return {
        ...state,
        pending: true,
        group: [],
      };
    case types.FETCH_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        group: actions.payload,
      };
    case types.FETCH_GROUP_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_GROUP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_GROUP_SUCCESS:
      //console.log(actions.payload.data);
      return {
        ...state,
        pending: false,
        group: state.map((item) => {
          if (actions.payload.data.id === item.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_GROUP_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_GROUP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        group: [...state.group, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_GROUP_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_GROUP_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_GROUP_SUCCESS:
      return {
        ...state,
        pending: false,
        // group: state.group.filter((g) => {
        //   return g !== actions.payload.data;
        // }),
        group: removeItemFromArray(state.group, actions.payload.data),
      };
    case types.REMOVE_GROUP_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
