import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  //console.log("actions", actions);
  switch (actions.type) {
    case types.INSERT_DEPLOYMENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        deployment: actions.payload.data,
      };
    case types.INSERT_DEPLOYMENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_DEPLOYMENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        deployment: actions.payload.data,
      };
    case types.UPDATE_DEPLOYMENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
