import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.HOT_WATER_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptHWT: null,
      };
    case types.HOT_WATER_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptHWT: actions.payload,
      };
    case types.HOT_WATER_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptHWT: null,
        error: actions.error,
      };
    case types.HOT_WATER_PARAMS_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
      };
    case types.HOT_WATER_PARAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.HOT_WATER_PARAMS_ERROR:
      return {
        ...state,
        pending: false,
        data: null,
        error: actions.error,
      };
    case types.SITES_BY_LOCATION_PARAMS_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
      };
    case types.SITES_BY_LOCATION_PARAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.SITES_BY_LOCATION_PARAMS_ERROR:
      return {
        ...state,
        pending: false,
        data: null,
        error: actions.error,
      };
    case types.SITES_BY_LOCATION_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptSBL: null,
      };
    case types.SITES_BY_LOCATION_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptSBL: actions.payload,
      };
    case types.SITES_BY_LOCATION_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptSBL: null,
        error: actions.error,
      };
    case types.USERS_BY_SITE_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptUBS: null,
      };
    case types.USERS_BY_SITE_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptUBS: actions.payload,
      };
    case types.USERS_BY_SITE_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptUBS: null,
        error: actions.error,
      };
    case types.USERS_BY_SITE_PARAMS_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
      };
    case types.USERS_BY_SITE_PARAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.USERS_BY_SITE_PARAMS_ERROR:
      return {
        ...state,
        pending: false,
        data: null,
        error: actions.error,
      };
    case types.SITES_BY_USER_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptSBU: null,
      };
    case types.SITES_BY_USER_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptSBU: actions.payload,
      };
    case types.SITES_BY_USER_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptSBU: null,
        error: actions.error,
      };
    case types.SITES_BY_USER_PARAMS_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
      };
    case types.SITES_BY_USER_PARAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.SITES_BY_USER_PARAMS_ERROR:
      return {
        ...state,
        pending: false,
        data: null,
        error: actions.error,
      };
    case types.TEMPERATURE_SUMMARY_PARAMS_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
      };
    case types.TEMPERATURE_SUMMARY_PARAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: actions.payload,
      };
    case types.TEMPERATURE_SUMMARY_PARAMS_ERROR:
      return {
        ...state,
        pending: false,
        data: null,
        error: actions.error,
      };
    case types.TEMPERATURE_SUMMARY_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptTS: null,
      };
    case types.TEMPERATURE_SUMMARY_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptTS: actions.payload,
      };
    case types.TEMPERATURE_SUMMARY_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptTS: null,
        error: actions.error,
      };
    case types.WASTE_SUMMARY_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptWS: null,
      };
    case types.WASTE_SUMMARY_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptWS: actions.payload,
      };
    case types.WASTE_SUMMARY_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptWS: null,
        error: actions.error,
      };
    case types.SPOTCHECK_SUMMARY_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        rptSC: null,
      };
    case types.SPOTCHECK_SUMMARY_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        rptSC: actions.payload,
      };
    case types.SPOTCHECK_SUMMARY_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        rptSC: null,
        error: actions.error,
      };
    default:
      return state;
  }
}
